export default [
  {
    title: "\"I'm so happy\"",
    content:
      "\"I had a regular notebook to record the times when my puppy had his meals, how much he ate; or when he slept, and for how long. Floof Journal just made it so easy for me. It is exactly what I needed! I'm so happy!\"",
    customerName: 'Melisa Duffy',
    customerTitle: 'Pet Owner',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: "\"My new obsession\"",
    content:
      "\"I never recorded anything, except for pup vaccinations. I now have one Floof Journal for each of my puppies where I record their sleep and diet habits. It'll be so awesome to give it to the new parents when they go home. I'm obsessed.\"",
    customerName: 'Kylie Johnson',
    customerTitle: 'Dog Breeder',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: "\"Pleasantly surprised!\"",
    content:
      "\"I didn't know anything about it until one of my visitors took it out to show the eating habits of their sick dog. It was so easy to understand. I bought one for my dog too, and I highly recommend it for all dog and cat owners. It really helps!\"",
    customerName: 'Lawrence White',
    customerTitle: 'Veterinarian',
    customerImage: 'https://placeimg.com/150/150/people'
  }
];