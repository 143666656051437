import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import customerData from '../data/customer-data';

// import HeroImage from '../images/HeroImage';
// import ChartIcon from '../images/chart.png'

import IdeaIcon from '../images/idea2.jpg'
import LabelText from '../components/LabelText';
import StatsBox from '../components/StatsBox';
import ListIcon from '../images/list.png'

import seoLogo from '../images/fjlogo256.png'
import journalImage from '../images/journal_cover_image.png'
import HeroImage from '../images/fjnew.png';
import SvgCharts from '../images/SvgCharts';
import DataIcon from '../images/data.png'

import SEO from '../components/SEO'

import CustomTemplateIcon from '../images/custom2.png'
import RealWorldIcon from '../images/realworldIcon.png'
import AnalysisIcon from '../images/analysis.png'
import ChartIcon from '../images/chartIcon2.png'
import EasyIcon from '../images/easyIcon2.png'
import myPetImage from '../images/mypet.png'

import { Link } from 'gatsby';

const seoImage = {
  src: seoLogo,
  height: 256,
  width: 256,
  alt: "Floof Journal Logo",
};

const journalSeoImage = {
  src: journalImage,
  height: 512,
  width: 345,
  alt: "Floof Journal",
};

const AmazonLink = "https://www.amazon.com/s?k=floof+journal"

const Index = () => (
  <Layout>

    <SEO
        siteTitle='Home'
        seoTitle='Floof Journal'
        path={"/"}
        metaImage={journalSeoImage}
        // icon={seoLogo}
        description='Floof Journal'
        keywords={["Floof", "Floof Journal", "Pet Journal", "Dog Journal", "Cat Journal"]}
        datePublished="09-17-2021"
        dateModified="09-17-2021"
      />

    <section id="home" className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Floof Journal<span className="fsupHdr">TM</span>
          </h1>
          <p className="text-md lg:text-md mt-6 font-light">
          Floof Journal<span className="fsup">TM</span> is created and designed by pet and animal lovers, just like yourself. 
          It is the result of hundreds of record keeping experiments, design iterations, and interviews with pet owners, caregivers, veterinarians, and breeders. Floof Journal<span className="fsup">TM</span>  is a modern, fun and easy way to keep a daily record of pet habits. With custom designed layout, it is easy to use, easy to read, and easy to understand.
          </p>

          <br />
          <p>
          "I strongly believe that understanding the behavior of our pets is vital to help them live a good life." - Felicia Young (Author)
          </p>
          <br />

          {/* <p className="mt-8 md:mt-12">
            <Link to="/aboutfj">
            <Button size="lg">Learn More</Button>
            </Link>
          </p> */}

          {/* <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> */}
        </div>
        <div className="lg:w-1/2">
          {/* <HeroImage /> */}
          <a href={AmazonLink} 
              target={"_blank"} rel="noreferrer" title={"Floof Journal on Amazon"}>
                <img style={{width: "60%", margin: "0 auto", paddingBottom: "1em"}} alt={"Floof Journal on Amazon"} src={HeroImage}/></a>
          {/* <img style={{width: "60%", margin: "0 auto", paddingBottom: "1em"}} src={HeroImage} /> */}
        </div>
      </div>
    </section>
    
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">

      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Innovative Design</h2>

        <p style={{padding: "2rem", paddingBottom: 0}}>
          {/* Floof Journal was designed in collaboration with pet owners, care providers, breeders, and veterinarians.  */}
          Floof Journal<span className="fsup">TM</span> is designed in collaboration with veterinarians, breeders, and animal care providers. We went back to the drawing board with quite a few purposes in mind. 
          We wanted to create something meaningful and helpful, something amazing and new - that is easy to use and easy to understand, that pet owners would love to use, and use it everyday. Floof Journal<span className="fsup">TM</span>  is a contemporary pet journal with a unique and modern design.
          {/* <br /><br />
          <div style={{margin: "0 auto", display: "table"}}>
          <ul style={{textAlign: "left", listStyleType: "disc"}}>
            <li>that is brand new</li>
            <li>that is amazing</li>
            <li>that is meaningful and helpful</li>
            <li>that is easy to use and easy to understand</li>
            <li>that pet owners would love to use</li>
            <li>that can be used on a daily basis</li>
            <li>that can help us understand our pets' habits</li>
            <li>that can help us understand our pet's behaviour</li>
          </ul>
          </div> */}
        </p>

        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
      
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <img style={{width: "40%", margin: "0 auto", paddingBottom: "1em"}} src={CustomTemplateIcon} />
              <p className="font-semibold text-xl">Custom Templates</p>
              <p className="mt-4">
                Custom data-block templates help record multiple data points quickly and easily.
              </p>
            </Card>
          </div>
      
          <div className="flex-1 px-3">
            <Card className="mb-8">
             <img style={{width: "40%", margin: "0 auto", paddingBottom: "1em"}} src={RealWorldIcon} />
              <p className="font-semibold text-xl">Real World Icons</p>
              <p className="mt-4">
                Custom designed real world icons helps you locate data with a quick glance.
              </p>
            </Card>
          </div>
      
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <img style={{width: "40%", margin: "0 auto", paddingBottom: "1em"}} src={EasyIcon} />
              <p className="font-semibold text-xl">Easy to Understand</p>
              <p className="mt-4">
                Contemporary design that is easy to use and easy to understand for everyone.
              </p>
            </Card>
          </div>

        </div>

        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">

          <div className="flex-1 px-3">
            <Card className="mb-8">
             <img style={{width: "40%", margin: "0 auto", paddingBottom: "1em"}} src={DataIcon} />
              <p className="font-semibold text-xl">Enhanced Data</p>
              <p className="mt-4">
                Easy to record multiple and enhanced data points in a compact and meaningful way.
              </p>
            </Card>
          </div>

          <div className="flex-1 px-3">
            <Card className="mb-8">
              <img style={{width: "40%", margin: "0 auto", paddingBottom: "1em"}} src={ChartIcon} />
              <p className="font-semibold text-xl">Habit Analysis</p>
              <p className="mt-4">
                {/* Separate diet, habit, and sleep data-blocks makes it easy to do a quick daily habit analysis. */}
                Separate data-blocks for diet, habit, and sleep helps in quick analysis of pet habits.
              </p>
            </Card>
          </div>

          <div className="flex-1 px-3">
            <Card className="mb-8">
              <img style={{width: "40%", margin: "0 auto", paddingBottom: "1em"}} src={AnalysisIcon} />
              <p className="font-semibold text-xl">Behavior Analysis</p>
              <p className="mt-4">
                A multi-day spread provides high level overview to help understand pet behavior.
              </p>
            </Card>
          </div>

        </div>

        <p style={{padding: "2rem", paddingBottom: 0}}>
          {/* Floof Journal was designed in collaboration with pet owners, care providers, breeders, and veterinarians.  */}
          Floof Journal<span className="fsup">TM</span> is available in various cover designs to suit every taste. Pick yours today!
        </p>
        <p className="mt-8 md:mt-12">
          <a className="menu" href={AmazonLink}
                target={"_blank"} rel="noreferrer" title={"Floof Journal on Amazon"}>
            <Button size="lg">Buy on Amazon</Button>
          </a>
        </p>
        
      </div>
    </section>

    <SplitSection
      id="research"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Research</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Independent research showed that pet owners tend to record different types of data for their pets, depending on what they want to track during a given time period.
            That means everybody has different needs and purposes, but similar and connected data points can easily be categorized together. 
            Floof Journal<span className="fsup">TM</span> then transformed these categories into custom designed data-blocks for easy data distribution and easy analysis later on.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />

    {/* <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Design And Plan Your Business Growth Steps
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Once the market analysis process is completed our staff will search for opportunities
            that are in reach
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    /> */}
    
    {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section> */}


    <section id="testimonials" className="py-20 lg:py-20">
      <div className="container mx-auto">
      <h3 className="text-2xl py-8 font-semibold text-center">What our users are saying</h3>
        {/* <LabelText className="mb-8 text-gray-600 text-center">What our users are saying</LabelText> */}
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>


    <SplitSection
      id="pledge"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Generate Donations</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
          Shop Floof Journal<span className="fsup">TM</span> and we'll donate 0.5% of all eligible purchases to charitable organizations, that will go towards helping local animal shelters, rescuers and animal welfare organizations. Your support can go a long way and can provide help or a loving home to each and every animal in need. Shop Floof Journal<span className="fsup">TM</span> today and share it with your family and friends today! 
          {/* You can also make a direct donation by using the link below. */}
          </p>

          {/* <p className="mt-8">
          <Button size="lg">Donate</Button>
          </p> */}

        </div>
      }
      // secondarySlot={<SvgCharts />}
      secondarySlot={
        <img style={{width: "80%", margin: "0 auto"}} src={myPetImage} />
      }
    />

    <section className="container mx-auto my-20 py-20 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Floof Journal<span className="fsup">TM</span></h3>
      <p className="mt-8 text-xl font-light">
        Contemporary journals for pet and animal lovers.
      </p>

      <p className="mt-8">
      <a className="menu" href={AmazonLink}
                target={"_blank"} rel="noreferrer" title={"Floof Journal on Amazon"}>
        <Button size="xl">Shop Now</Button>
      </a>
      </p>
    </section>

  </Layout>
);

export default Index;
